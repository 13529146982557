import React from 'react';

import useGetNews from '../../components/Hooks/useGetNews';
import Slider from '../../components/Slider/Slider';
import NewsList from '../../components/NewsList/NewsList';
import Spinner from '../../components/Spinner/Spinner';

import { cardType} from '../../components/NewsList/NewsListTypes';

const News = () => {


    const { sliderNews, newsList, finishedNews} = useGetNews();

    let loadingSpinner=null;


    if (!finishedNews) {
        loadingSpinner = <Spinner />
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const newsListSettings = {
        type : cardType.card,
        loadMore: true,
        amountToShow: 5,
        additionalAmountToShow: 5
    }

    return (
        <div>
            {loadingSpinner}
            <Slider data={sliderNews} settings={sliderSettings} />
            <NewsList data={newsList} settings={newsListSettings} />
        </div>
    );
};

export default News;
