import React from 'react';

import {propsType} from './DrawerToggleTypes';
import classes from './DrawerToggle.module.css';

const DrawerToggle = (props : propsType) => {
    return (
        <div className={classes.DrawerToggle} onClick={props.drawerToggleClicked} >
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default DrawerToggle;
