import React, { useState, useEffect } from 'react';
import { TransitionGroup } from 'react-transition-group';

import Button from '../Button/Button';
import RenderList from '../RenderList/RenderList';

import { propsType} from './NewsListTypes';
import classes from './NewsList.module.css';

const NewsList = (props: propsType) => {

    const { data, settings } = props;
    const type = settings.type;
    const additionalAmountToShow = settings.additionalAmountToShow;
    const minimalAmountToShow = settings.amountToShow;

    const [newsData, setnewsData] = useState(data);
    const [amountToShow, setAmountToShow] = useState(settings.amountToShow);

    useEffect(() => {
        setnewsData(data?.slice(0,amountToShow));
    }, [data, amountToShow]);

    const loadMoreHandle = () => {
        setAmountToShow(amountToShow + additionalAmountToShow);
    }

    const newsList = () => {
        const list = RenderList({type:type,data:newsData, video:false});
        return list?.map((item) => {
            return(item)
        })
    }

    const reduceHandler = () => {
        setAmountToShow(minimalAmountToShow)
    }

    const reduceButton = () => {
        return (
            <div className={classes.LoadMore} >
                <Button label=" Reduce " clicked={reduceHandler}/>
            </div>
        )
    }

    const loadMoreButton = () => {
        return (
            <div className={classes.LoadMore} >
                <Button label="Load More News !!" clicked={loadMoreHandle}/>
            </div>
        )
    }

    return (
        <div className={classes.NewsList}>
                <TransitionGroup>
                    {newsList()}
                </TransitionGroup>

                {  !data ? null : amountToShow >= data.length ? reduceButton():loadMoreButton()}
        </div>
    );
};

export default NewsList;
