import React from 'react';

import classes from './Button.module.css';
import { propsType } from './ButtonTypes';

const Button = (props: propsType) => {
    return (
        <div
            className={classes.Button}
            onClick={props.clicked}
        >
            {props.label}
        </div>
    )

};

export default Button;
