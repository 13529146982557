import React from 'react';

import Slider from '../../components/Slider/Slider';
import NewsList from '../../components/NewsList/NewsList';
import VideoList from '../../components/VideosList/VideoList';
import Spinner from '../../components/Spinner/Spinner';
import useGetAll from '../../components/Hooks/useGetAll';


import { cardType} from '../../components/NewsList/NewsListTypes';

const Home = () => {

  const { finished, sliderNews:apiDataSlider,
          newsList:apiDataNews, allVideos:apiVideoNews} = useGetAll();

  let loadingSpinner=null;

  if (!finished) {
    loadingSpinner = <Spinner />
  }

  const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1
  };

  // const typeCard: cardType = 'hghghhghg';

const newsListSettings = {
  type : cardType.card,
  loadMore: true,
  amountToShow: 3,
  additionalAmountToShow: 3
}

const videoListSettings = {
  type : cardType.card,
  title: true,
  loadMore: true,
  amountToShow: 3,
  additionalAmountToShow: 3
}

  return (
      <div>

        {loadingSpinner}

        <Slider data={apiDataSlider} settings={sliderSettings}/>

        <NewsList data={apiDataNews} settings={newsListSettings} />

        <VideoList data={apiVideoNews} settings={videoListSettings} />

      </div>
  );

};

export default Home;
