import React from 'react';

import classes from './Backdrop.module.css';
import { propsType } from './BackdropTypes';

const Backdrop = (props:propsType) => (

    props.show ? <div className={classes.Backdrop} onClick={props.clicked} ></div> : null
)

export default Backdrop;
