import React from 'react';

import nbaLogo from '../../../src/assets/images/nba_logo.png';

import styles from './Logo.module.css';


const Logo = () => {
    return (
        <div className={styles.Logo}>
            <img alt="NBA Logo" src={nbaLogo}/>
        </div>
    );
};

export default Logo;
