import { useEffect, useState } from 'react';
import axios from 'axios';

import {dataType, objType } from '../NewsList/NewsListTypes';

const useGetNews = () => {

    const [sliderNews, setSliderNews] = useState<dataType>([] as dataType);
    const [newsList, setnewsList] = useState<dataType>([] as dataType);
    const [finishedNews, setfinished] = useState(false);

    useEffect(() => {
        let url;
        if (process.env.NODE_ENV !== 'production') {url= 'api/news'}
        else {url = process.env.REACT_APP_APIHOST + '/news'}

        axios.get(url)
        .then(res => {
            const otherNews= res.data.filter(
                (item:objType)  => item.source === "nba"
            ).slice(0,5);
            setSliderNews(otherNews);
            const restData = res.data.filter((allNews:objType) =>
                            !otherNews.map((slider:objType)  =>
                                    slider.title).includes(allNews.title)
                    )
            setnewsList(restData);
            setfinished(true);
        })
    }, []);

    return {sliderNews, newsList, finishedNews};
};

export default useGetNews;
