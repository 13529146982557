import React from 'react';

import Slider from "react-slick";

import { propsType} from './SliderTypes';
import classes from './Slider.module.css';


const SliderComponent = ( props: propsType) => {

    const {data, settings} = props ;

    const template = !data ? null : data.map((item, i) => {
        return (
            <div key={i}>
                <div className={classes.Item}>
                    <div className={classes.Image}
                        style={{
                            background:`url(${item.image})`
                        }}
                    >
                        <a rel="noreferrer" href={item.url} target="_blank" >
                            <div className={classes.Title}>
                                {item.title}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className={classes.Slider} >
            <Slider {...settings}>
                {template}
            </Slider>
        </div>
    );
};

export default SliderComponent;
