import React, { useState} from 'react';

import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import BackDrop from '../../../components/Backdrop/Backdrop';
import SideNav from '../../../components/SideNav/SideNav';

import { PropsType } from './LayoutTypes';
import style from './Layout.module.css';

const Layout = (props : PropsType) => {

    const [drawerOpen, setdrawerOpen] = useState(false);

    const clickDrawerHandle = () => {
        setdrawerOpen(!drawerOpen);
    }

    return (
        <React.Fragment>
            <BackDrop show={drawerOpen} clicked={clickDrawerHandle} />
            <SideNav open={drawerOpen} sideDrawerClosed={clickDrawerHandle} />
            <div className={style.WholeScreen}>
                <Header drawerToggleClicked={clickDrawerHandle}/>
                <div className={style.Main}>
                    {props.children}
                </div>
                <div className={style.Footer}>
                    <Footer />
                </div>

            </div>

        </React.Fragment>

    );
};

export default Layout;
