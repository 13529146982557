import React from 'react';
import Slider from '../../components/Slider/Slider';
import VideoList from '../../components/VideosList/VideoList';
import useGetVideos from '../../components/Hooks/useGetVideos';
import Spinner from '../../components/Spinner/Spinner';

import { cardType} from '../../components/NewsList/NewsListTypes';
import classes from './Videos.module.css';

const Videos = () => {


    const { sliderVideos, videosList, finishedVideo } = useGetVideos();

    let loadingSpinner=null;

    if (!finishedVideo) {
        loadingSpinner = <Spinner />
    }




    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const videoListSettings = {
        type : cardType.card,
        title: false,
        loadMore: true,
        amountToShow: 5,
        additionalAmountToShow: 5
    }

    return (
        <div>
            {loadingSpinner}
            <Slider data={sliderVideos} settings={sliderSettings} />
            <div className={classes.VideoDiv} >
                <VideoList data= {videosList} settings={videoListSettings} />
            </div>

        </div>
    );
};

export default Videos;
