
import useGetNews from './useGetNews';
import useGetVideos from './useGetVideos';

const useGetAll = () => {


    const { sliderNews, newsList, finishedNews } = useGetNews();
    const {sliderVideos, videosList, finishedVideo} = useGetVideos();
    const allVideos = sliderVideos ? sliderVideos.concat(videosList) : []

    const finished = finishedVideo && finishedNews;
    return { finished, sliderNews, newsList, allVideos };
};

export default useGetAll;
