import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Footer.module.css';

import Logo from '../../components/Logo/Logo';

const Footer = () => {

    const year = (new Date()).getFullYear();
    const logo = (
        <Link to="/">
            <Logo />
        </Link>
    )

    return (
        <div className={classes.footer} >
            {logo}
            <div className={classes.rights}>
                @JPDB {year} All Rights Reserved
            </div>
        </div>
    );
};

export default Footer;
