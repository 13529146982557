import React from 'react';
import classes from './Spinner.module.css';

const Spinner = () => {
    return (
        <div className={classes.middle}>
            <div className={[classes.bar, classes.bar1].join(' ')}></div>
            <div className={[classes.bar, classes.bar2].join(' ')}></div>
            <div className={[classes.bar, classes.bar3].join(' ')}></div>
            <div className={[classes.bar, classes.bar4].join(' ')}></div>
            <div className={[classes.bar, classes.bar5].join(' ')}></div>
            <div className={[classes.bar, classes.bar6].join(' ')}></div>
            <div className={[classes.bar, classes.bar7].join(' ')}></div>
            <div className={[classes.bar, classes.bar8].join(' ')}></div>
        </div>
    );
};

export default Spinner;
