import React from 'react';
import { CSSTransition} from 'react-transition-group';

import classes from './RenderList.module.css';
import { propsType} from './RenderListTypes';
import playIcon from '../../assets/images/play.png';

const RenderList = (props:propsType) => {

    const { type, data, video } = props;

    const templateCard  = ()  => {
        template = data?.map((item, idx) => {
            const itemRef = React.createRef<HTMLDivElement>();
            return (
                <CSSTransition
                    nodeRef={itemRef}
                    classNames={{
                        enter:classes.ItemEnter,
                        enterActive: classes.ItemEnterActive,
                        enterDone: classes.ItemEnterDone,
                        exit: classes.ItemExit,
                        exitActive: classes.ItemExitActive,
                        exitDone: classes.ItemExitDone
                    }}
                    timeout={500}
                    key={idx}
                >
                    <a rel="noreferrer" href={item.url} target="_blank" >
                        <div ref={itemRef} className={classes.Item} >
                            <div className={classes.Left} >
                                <img src={`${item.image}` } alt={item.title} />
                                { video &&
                                    <div className={classes.Play} >
                                        <img src={playIcon} alt="Play Icon" />
                                    </div>
                                }
                            </div>
                            <div className={classes.WrapperRight}>
                                <div className={classes.Right} >
                                    <h2>{item.title}</h2>
                                </div>
                            </div>
                        </div>
                    </a>
                </CSSTransition>
            )
        })

        return template;
    }

    let template;

    switch (type) {
        case "card":
            template = templateCard();
            break;
        default:
            template = templateCard();
            break;
    }
    return template;
};

export default RenderList;
