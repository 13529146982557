import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from '../../containers/Home/Home';
import News from '../../containers/News/News';
import Videos from '../../containers/Videos/Videos';
import Layout from '../../containers/hoc/Layout/Layout';

const ApplicationRoutes = () => {
    let allowedRoutes = (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/news" element={<News/>} />
            <Route path="/videos" element={<Videos/>} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>

    )

    return (
        <Layout>
            {allowedRoutes}
        </Layout>
    );
};

export default ApplicationRoutes;
