import React from 'react';
import NavigationItems from '../NavigationItems/NavigationItems';

import classes from './SideNav.module.css';
import { propsType} from './SideNavTypes';

const SideNav = (props:propsType) => {
    let drawerClasses
    if (props.open) { drawerClasses = [classes.SideDrawer].concat([classes.Open]).join(' ')}
    else { drawerClasses = [classes.SideDrawer].concat([classes.Close]).join(' ')}
    return (
        <React.Fragment>
            <div className={drawerClasses} onClick={props.sideDrawerClosed}>
                <nav>
                    <NavigationItems />
                </nav>
            </div>
        </React.Fragment>
    );
};

export default SideNav;
