import React, { useState, useEffect } from 'react';
import { TransitionGroup } from 'react-transition-group';

import Button from '../Button/Button';
import RenderList from '../RenderList/RenderList';

import { propsType } from './VideoListTypes';
import classes from './VideoList.module.css';

const VideoList = (props: propsType) => {

    const { data, settings } = props;

    const additionalAmountToShow = settings.additionalAmountToShow;
    const minimalAmountToShow = settings.amountToShow;

    const [showedVideo, setShowedVideo] = useState(data);
    const [amountToShow, setAmountToShow] = useState(settings.amountToShow);


    useEffect(() => {
            setShowedVideo(data.slice(0,amountToShow));
    }, [data, amountToShow]);


    const renderTitle = () => {
        return settings.title ? <h3><strong>NBA</strong> Videos</h3> : null
    }

    const videosList = () => {
        const list = RenderList({type:settings.type, data:showedVideo, video:true});
        return list?.map((item) => {
            return(item)
        })
    }

    const loadMoreHandler = () => {
        setAmountToShow(amountToShow + additionalAmountToShow)
    }

    const reduceHandler = () => {
        setAmountToShow(minimalAmountToShow)
    }

    const reduceButton = () => {
        return (
            <div className={classes.LoadMore} >
                <Button label=" Reduce " clicked={reduceHandler}/>
            </div>
        )
    }

    const loadMoreButton = () => {
        return (
            <div className={classes.LoadMore} >
                <Button label="Load More Videos !!" clicked={loadMoreHandler}/>
            </div>
        )
    }

    return (
        <div className={classes.VideosList}>
            {renderTitle()}
            <TransitionGroup>
                {videosList()}
            </TransitionGroup>
            {  !data ? null : amountToShow >= data.length ?
                        reduceButton():
                        loadMoreButton()
            }
        </div>

    );
};

export default VideoList;
