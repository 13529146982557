import classes from './NavigationItems.module.css';
import { faHome, faFileText, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NavigationItem from '../NavigationItem/NavigationItem';

const NavigationItems = () => {
    return (
        <ul className={classes.NavigationItems}>
            <NavigationItem link="/">
            <FontAwesomeIcon icon={faHome} />
                Home
            </NavigationItem>

            <NavigationItem link="/news">
            <FontAwesomeIcon icon={faFileText} />
                News
            </NavigationItem>

            <NavigationItem link="/videos">
                <FontAwesomeIcon icon={faPlayCircle} />
                Videos
            </NavigationItem>
        </ul>
    );
};

export default NavigationItems;
