import React  from 'react';


import Logo from '../../components/Logo/Logo';
import DrawerToggle from '../../components/DrawerToggle/DrawerToggle';

import classes from './Header.module.css';
import { Link } from 'react-router-dom';

import {propsType} from './HeaderTypes';

const Header = (props: propsType) => {

    const logo = (
            <Link to="/">
                <Logo />
            </Link>
    )

    return (
        <header className={classes.header} >
            <div className={classes.headerOpt} >
                <DrawerToggle drawerToggleClicked={props.drawerToggleClicked}/>
                {logo}
            </div>
        </header>
    );
};

export default Header;
