import { useEffect, useState } from 'react';
import axios from 'axios';

import {dataType} from '../VideosList/VideoListTypes';

const useGetVideos = () => {

    const [sliderVideos, setSliderVideos] = useState<dataType>([] as dataType);
    const [videosList, setVideosList] = useState<dataType>([] as dataType);
    const [finishedVideo, setfinished] = useState(false);

    useEffect(() => {
        let url;
        if (process.env.NODE_ENV !== 'production') {url= 'api/news/videos'}
        else {url = process.env.REACT_APP_APIHOST + '/news/videos'}

        axios.get(url)
        .then(res => {
            setSliderVideos (res.data.slice(0,5));
            setVideosList(res.data.slice(5));
            setfinished(true);
        })
    }, []);

    return {sliderVideos, videosList, finishedVideo};
};

export default useGetVideos;
