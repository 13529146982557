export type objType = {
    image:  string;
    source: string;
    title:  string;
    url:    string;
}

export type dataType = objType[];

export enum cardType  {
    card = "card"
};

export type settingsType = {
    type : cardType,
    loadMore:boolean,
    amountToShow: number,
    additionalAmountToShow: number
};

export type propsType = {data: dataType, settings: settingsType};
